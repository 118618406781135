import * as React from 'react'

import EspaceLayout from '../components/espaceLayout'
import museeLR from '../assets/img/museum_la_rochelle.jpg'

const LaRochellePage = () => {
  return (
    <EspaceLayout nomVille={process.env.lr} imageSrc={museeLR}>
      <p>Le muséum d'histoire naturelle de La Rochelle est un musée d'histoire naturelle français situé dans la ville de La Rochelle,
         en Charente-Maritime. Il est installé dans l'ancien palais du gouverneur d'Aunis. Le bâtiment est inscrit au titre des
         monuments historiques par arrêté du 27 octobre 2003.</p>
    </EspaceLayout>
  )
} 

export default LaRochellePage
