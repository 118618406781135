import * as React from 'react'

import NavbarLayout from '../components/navbarLayout'
import FooterLayout from '../components/footerLayout'

const EspaceLayout = ({nomVille, imageSrc, children}) => {
  return (
    <body>
      <header className="main_headernav">
        <NavbarLayout> </NavbarLayout>
      </header>

      <section className="news" style={{marginTop:100}}>
        <h2>{nomVille}</h2>
        <div className="wrap">
          <div className="news-item item">
            <div>
              {children}
            </div>
            <img src={imageSrc} />
          </div>
        </div>
      </section>

      <FooterLayout></FooterLayout>
    </body>
  )
}

export default EspaceLayout